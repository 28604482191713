import { REACTION } from '~/modules/reactions/types';
import { TAG } from '~/types/tag';

export type AiEditType = {
  id: string;
  text: string;
  reaction: REACTION;
};

export type ToolExecutionType = {
  id: string;
  text: string;
  symbols: number;
  reaction: REACTION;
  words: number;
  ai_edit_items: AiEditType[];
  children: ToolExecutionType[];
  user_inputs: Record<string, any>;
  created_at: string;
  updated_at: string;
};

export type ArticleExecutionType = {
  id: string;
  text: string;
  symbols: number;
  reaction: REACTION;
  words: number;
  title: string;
  created_at: string;
  updated_at: string;
};

export enum CardColor {
  ACCENT = 'accent',
  DEFAULT = 'default',
  GRADIENT = 'gradient',
}

export type ToolType = {
  id: string;
  slug: string;
  category_id: string;
  category_name: string;
  image_src?: string;
  symbol?: string;
  path?: string;
  title: string;
  description: string;
  tags: TAG[];
  color: CardColor;
  is_limited: boolean;
};

export type GroupToolsType = {
  group_name: string;
  group_id: string;
  items: ToolType[];
};

export enum FORM_FIELDS {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TAGS = 'tags',
  SELECT = 'select',
  COUNTER = 'counter',
  TOGGLE = 'toggle',
  SWITCH = 'switch',
  SELECT_CUSTOM_OPTION = 'select_custom_option',
  TEXTAREA_UPLOAD = 'textarea_upload',
  INSERT_LINK_META = 'insert_link_meta',
}

export type FormDataType = Record<string, string | number | boolean>;

export type UiFieldType = {
  $ref?: { field: string; value: string | number | boolean }[];
  title: string;
  type: FORM_FIELDS;
  props: {
    placeholder?: string;
    options?: { text: string; value: string }[];
    required: boolean;
    hidden?: boolean;
    defaultValue?: string | number | boolean;
    minValue?: number;
    maxValue?: number;
    maxCountIndicator?: number;
  };
  max_count?: number;
};

export type ExtendedToolType = ToolType & {
  validation_schema: Record<string, any>;
  ui_schema: Record<string, UiFieldType>;
  last_inputs: Record<string, string>;
};

export type OutlineItemType = {
  id: string;
  content: string;
  items: OutlineItemType[];
};

export type ToolCategoryType = {
  id: string;
  name: string;
  description: string;
};

export type FailedRequestType = {
  errorMessage: string;
  requestFormData: FormDataType;
};
