import { TFunction } from 'i18next';

export const ImageGeneratorSidebarKey = 'image_generator';

export const ImageGeneratorPath = '/image_generation';

export const getImageGeneratorTitle = (t: TFunction): string =>
  t('sidebar:image_generator', 'Image Generator');

export const getNewLabel = (t: TFunction): string =>
  t('general:new', 'New').toUpperCase() + '!';

export const ImageGeneratorId = '9c5b646e-2b20-463f-a26d-18e422f5275e';

export const MIN_SYMBOLS_INSTRUCTION_IMPROVE = 8;

export const DEFAULT_MAX_IMAGE_GENERATIONS_DAILY_LIMIT = 10;

export const MAX_SYMBOLS_INSTRUCTION_FIELD = 3800;
