import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { AiResearcherPath, FactCheckerPath } from '~/modules/tools/constants';
import { checkIsAiResearcherAccessible } from '~/modules/tools/views/AiResearcher/helpers/helpers';
import AiResearcherIntroModal from '~/modules/tools/views/AiResearcher/IntroModal';
import { checkIsFactCheckerAccessible } from '~/modules/tools/views/FactChecker/helpers';
import FactCheckerIntroModal from '~/modules/tools/views/FactChecker/IntroModal';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { capitalizeCaseToSnakeCase } from '~/utils/text';

type ModalConfig = {
  key: 'ai researcher' | 'fact checker';
  condition: boolean;
  modalComponent: (
    onClose: () => void,
    updateFields: () => void,
  ) => JSX.Element;
};

type Props = {
  children?: React.ReactNode;
};

const NewToolsIntroWrapper: FC<Props> = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);

  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn || !profile.is_onboarded) {
      return;
    }

    const modals: ModalConfig[] = [
      {
        key: 'fact checker',
        condition:
          checkIsFactCheckerAccessible(profile) &&
          !profile.extra_fields?.notifications?.fact_checker_intro_modal,
        modalComponent: (onClose, updateFields) => (
          <FactCheckerIntroModal
            onSubmit={() => {
              analytics.trackEvent('fact checker - modal click');
              updateFields();
              onClose();
              window.setTimeout(() => navigate(FactCheckerPath), 1000);
            }}
            onRefuse={() => {
              analytics.trackEvent('fact checker - modal dismiss');
              updateFields();
              onClose();
            }}
          />
        ),
      },
      {
        key: 'ai researcher',
        condition:
          checkIsAiResearcherAccessible(profile) &&
          Boolean(profile.extra_fields?.sign_up_at) &&
          Math.abs(
            DateService.differenceInDays(
              new Date(profile.extra_fields.sign_up_at || new Date().getTime()),
              new Date(),
            ),
          ) >= 1 &&
          !profile.extra_fields?.notifications?.ai_researcher_intro_modal,
        modalComponent: (onClose, updateFields) => (
          <AiResearcherIntroModal
            onSubmit={() => {
              analytics.trackEvent('ai researcher - modal click');
              updateFields();
              onClose();
              window.setTimeout(() => navigate(AiResearcherPath), 1000);
            }}
            onRefuse={() => {
              analytics.trackEvent('ai researcher - modal dismiss');
              updateFields();
              onClose();
            }}
          />
        ),
      },
    ];

    const modalToShow = modals.find((modal) => modal.condition);

    if (!modalToShow) {
      return;
    }

    const updateFields = (): void => {
      updateUserExtraFields({
        notifications: {
          ...profile.extra_fields?.notifications,
          [`${capitalizeCaseToSnakeCase(modalToShow.key)}_intro_modal`]: 1,
        },
      });
    };

    handleOpenModal({
      disableBackdropClick: true,
      hideCloseButton: true,
      onShow: () => analytics.trackEvent(`${modalToShow.key} - modal show`),
      component: ({ onClose }) =>
        modalToShow.modalComponent(onClose, updateFields),
    });
  }, [profile]);

  return <>{children}</>;
};

export { NewToolsIntroWrapper };
