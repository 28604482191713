import React, { FC, useMemo } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import BlackTooltip from '~/components/atoms/BlackTooltip';
import Button from '~/components/atoms/buttons/Button';
import RestoreButton from '~/components/atoms/buttons/RestoreButton';
import UpgradeNowButton from '~/components/atoms/buttons/UpgradeNowButton';
import { getButtonSuggestion } from '~/components/layouts/MainLayout/components/ButtonSuggestion/helpers';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import { PRODUCT } from '~/constants/products';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import UnpauseSuggestionButton from '~/modules/cancellation/features/pause/components/UnpauseSuggestionButton';
import usePauseSubscription from '~/modules/cancellation/features/pause/hooks/usePauseSubscription';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import { useRestoreSubscription } from '~/modules/settings/hooks/useRestoreSubscription';
import analytics from '~/services/analytics';
import extension from '~/services/Extension';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';
import { timeout } from '~/utils/common';

import styles from './styles.module.scss';

export enum BUTTON_SUGGESTION {
  RESTORE = 'restore',
  UPGRADE = 'upgrade',
  EXTENSION = 'extension',
  REDEMPTION = 'redemption',
  REDEMPTION_PENDING = 'redemption_pending',
  REDEMPTION_FAILED = 'redemption_failed',
  UNPAUSE = 'unpause',
}

const extensionTitle = extension.getTitle();
const ExtensionIcon = extension.getIcon();
const extensionUrl = extension.getExtensionUrl();

const ButtonSuggestion: FC = () => {
  const {
    id,
    user_subscription,
    user_product,
    is_chrome_extension_installed,
    is_stripe_user,
  } = useAppSelector((state) => state.profile);

  const { t } = useTranslation('general');

  const { reportUserErrors } = useErrors();
  const { handleOpenModal } = useModalContext();
  const { restoreSubscription } = useRestoreSubscription();
  const { loadAndSetActualProfileData } = useActions();
  const { handleUnpauseSub } = usePauseSubscription();

  const handleClickExtension = (): void => {
    analytics.trackEvent('subscription - extension promo button click');
    window.open(extensionUrl, '_blank', 'noopener,noreferrer');
  };

  const handleRestoreSubscription = async (): Promise<void> => {
    analytics.trackEvent('subscription - restore sub', {
      sub: user_subscription.subscription_name,
    });

    if (user_subscription.status === SUBSCRIPTION_STATUS.CANCEL_PENDING) {
      try {
        await restoreSubscription();
        await timeout(5000);
      } catch (e) {
        reportUserErrors({
          error: e,
          method: 'restoreSubscription',
          userMessage: t(
            'error:unable_restore_subscription',
            'Unable to restore subscription. Please contact support or',
          ),
        });
      }
      return;
    }

    handleOpenModal({
      onClose: () => {
        analytics.trackEvent('subscription - close not active sub screen', {
          sub: user_subscription.subscription_name,
        });
      },
      maxWidth: '460px',
      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={(): void => {
            onClose();
          }}
          type={ReasonType.NO_ACTIVE_SUBSCRIPTION}
        />
      ),
    });

    analytics.trackEvent('subscription - not active sub screen', {
      sub: user_subscription.subscription_name,
    });
  };

  const handleRestoreSubscriptionHard = async (): Promise<void> => {
    analytics.trackEvent('subscription - redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'header',
    });
    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION}
        />
      ),
    });
  };

  const handleRedemptionFailed = async (): Promise<void> => {
    analytics.trackEvent('subscription - failed redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'header',
    });

    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION_FAILED}
        />
      ),
    });
  };

  const handleUpgradeSubscription = async (): Promise<void> => {
    analytics.trackEvent('subscription - sub promo button click');

    try {
      const productById = await Api.getProductById(PRODUCT.ANNUAL_SUB_OFFER);

      analytics.trackEvent('subscription - change sub', {
        sub_id: productById.id,
      });

      handleOpenModal({
        onClose: () => {
          analytics.trackEvent('platform checkout - close');
        },
        ...paymentFormModalConfig,
        component: ({ onClose }) => (
          <PaymentForm
            userId={id}
            product={productById}
            onSuccessPayment={async (): Promise<void> => {
              await timeout(4000);
              await loadAndSetActualProfileData();
              onClose();
            }}
          />
        ),
      });
    } catch (e: any) {
      reportUserErrors({
        error: e,
        method: 'getProductById',
        userMessage: 'Unable to fetch product details. Please retry or',
      });
    }
  };

  const SuggestionButton = useMemo(() => {
    const buttonSuggestion = getButtonSuggestion({
      subStatus: user_subscription.status,
      regularity: user_product.regularity,
      isExtensionInstalled: is_chrome_extension_installed,
      isStripeUser: is_stripe_user,
    });

    if (!buttonSuggestion) {
      return <></>;
    }

    switch (buttonSuggestion) {
      case BUTTON_SUGGESTION.RESTORE:
        return <RestoreButton onClick={handleRestoreSubscription} />;

      case BUTTON_SUGGESTION.REDEMPTION:
        return (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="material-symbols:error-outline" />}
            onClick={handleRestoreSubscriptionHard}
            className={clsx(styles.button, styles['button--redemption'])}
          >
            {t('payment_issues', 'Payment Issues')}
          </Button>
        );

      case BUTTON_SUGGESTION.REDEMPTION_PENDING:
        return (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="line-md:loading-loop" width="24" />}
            className={clsx(
              styles.button,
              styles['button--redemption-pending'],
            )}
          >
            {t('fixing_payment_issue', 'Fixing payment issue...')}
          </Button>
        );

      case BUTTON_SUGGESTION.REDEMPTION_FAILED:
        return (
          <BlackTooltip
            placement="bottom"
            title="Your current payment method is not working. Update it to continue enjoying features of Justdone"
          >
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              startIcon={<Icon icon="material-symbols:error-outline" />}
              onClick={handleRedemptionFailed}
              className={clsx(styles.button, styles['button--redemption'])}
            >
              {t('update_payment_method', 'Update Payment Method')}
            </Button>
          </BlackTooltip>
        );

      case BUTTON_SUGGESTION.UNPAUSE:
        return <UnpauseSuggestionButton onClick={handleUnpauseSub} />;

      case BUTTON_SUGGESTION.UPGRADE:
        return <UpgradeNowButton onClick={handleUpgradeSubscription} />;

      case BUTTON_SUGGESTION.EXTENSION:
        return (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<ExtensionIcon />}
            onClick={handleClickExtension}
            className={clsx(styles.button, styles['button--extension'])}
          >
            {t('add_chrome_extension', {
              title: extensionTitle,
              defaultValue: 'Add {{title}} Extension',
            })}
          </Button>
        );

      default:
        return <></>;
    }
  }, [
    is_stripe_user,
    user_subscription,
    user_product,
    is_chrome_extension_installed,
  ]);

  return <>{SuggestionButton}</>;
};

export default ButtonSuggestion;
