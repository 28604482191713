export enum AB_TESTS_KEYS {
  CHAT_ONBOARDING = 'chat_onboarding',
  TRUSTPILOT_SCENARIO = 'trustpilot_scenario',
  RATEBAR_COLLECTION_FEEDBACK = 'ratebar-collection-type',
  PAYPAL_SPINNER_1HOUR_REVERT = 'paypal-spinner-1hour-revert',
  CHROME_EXTENSION_ONBOARDING_CONFIGURATOR = 'chrome-extension-onboarding-configurator',
  WORDS_UPSELL_OPTION = 'words_upsell_option',
  ANSWER_EXAMPLE_RESPONSE = 'answer_example_response',
  INPUT_LIMIT_SKIP_TRIAL = 'input_limit_skip_trial',
  PWA_COMMUNICATION_V5 = 'pwa-communication-v5',
  WORDS_EXCEED_MODAL = 'words-exceed-modal',
  ES_LOCALIZATION = 'es_localization',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      [key: string]: string;
    };
    defaultVariant: string;
    isManualStart?: boolean;
  }
> = {
  [AB_TESTS_KEYS.CHAT_ONBOARDING]: {
    key: AB_TESTS_KEYS.CHAT_ONBOARDING,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.TRUSTPILOT_SCENARIO]: {
    key: AB_TESTS_KEYS.TRUSTPILOT_SCENARIO,
    variants: {
      control: 'control',
      variant_2: 'tested',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.RATEBAR_COLLECTION_FEEDBACK]: {
    key: AB_TESTS_KEYS.RATEBAR_COLLECTION_FEEDBACK,
    variants: {
      control: 'control', // with emoji
      variant_1: 'test', // with stars
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.PAYPAL_SPINNER_1HOUR_REVERT]: {
    key: AB_TESTS_KEYS.PAYPAL_SPINNER_1HOUR_REVERT,
    variants: {
      control: 'without_spinner',
      variant_1: 'with_spinner',
    },
    defaultVariant: 'without_spinner',
  },
  [AB_TESTS_KEYS.CHROME_EXTENSION_ONBOARDING_CONFIGURATOR]: {
    key: AB_TESTS_KEYS.CHROME_EXTENSION_ONBOARDING_CONFIGURATOR,
    variants: {
      control: 'tutorial',
      variant_1: 'configurator',
    },
    defaultVariant: 'tutorial',
  },
  [AB_TESTS_KEYS.ANSWER_EXAMPLE_RESPONSE]: {
    key: AB_TESTS_KEYS.ANSWER_EXAMPLE_RESPONSE,
    variants: {
      control: 'control',
      variant_1: 'test',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.WORDS_UPSELL_OPTION]: {
    key: AB_TESTS_KEYS.WORDS_UPSELL_OPTION,
    variants: {
      control: 'control',
      variant_1: 'upsell',
      variant_2: 'upsell_words',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.INPUT_LIMIT_SKIP_TRIAL]: {
    key: AB_TESTS_KEYS.INPUT_LIMIT_SKIP_TRIAL,
    variants: {
      control: 'control',
      variant_1: 'test',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.WORDS_EXCEED_MODAL]: {
    key: AB_TESTS_KEYS.WORDS_EXCEED_MODAL,
    variants: {
      control: 'control',
      variant_1: 'test',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V5]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V5,
    variants: {
      control: '4.1',
      variant_1: '5.0',
    },
    defaultVariant: '4.1',
  },
  [AB_TESTS_KEYS.ES_LOCALIZATION]: {
    key: AB_TESTS_KEYS.ES_LOCALIZATION,
    variants: {
      control: 'en',
      variant_1: 'es',
    },
    defaultVariant: 'en',
    isManualStart: true,
  },
};
